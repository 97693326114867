import IconChart from "./icon-chart.svg";
import IconSuccess from "./icon-success.png";
import IconWarning from "./icon-warning.png";
import IconLetterModo from "./icon-letter-modo.svg";

type Map = {
  [key: string]: string | undefined
}

const Icons: Map = {
  "icon-chart": IconChart,
  "icon-success": IconSuccess,
  "icon-warning": IconWarning,
  "icon-letter-modo": IconLetterModo
};

export default Icons;


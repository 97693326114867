import { useState } from "react";
import styles from "./baseCheckbox.module.scss";

type TBaseInput = {
  name?: string;
  isChecked: boolean;
  label?: string;
  onChange?: any;
}

const BaseCheckbox: React.FC<TBaseInput> = ({ isChecked, label, name, onChange }: TBaseInput) => {

  const [isEnabled, setIsEnabled] = useState<boolean>(isChecked);

  const toggleChange = () => {
    setIsEnabled(!isEnabled);
    onChange(!isEnabled);
  };

  return (
    <div className={styles.checkbox__wrapper} >
      <label className={styles.input__label}>
        <input
          className={styles.checkbox}
          type="checkbox"
          name={name}
          value="true"
          checked={isEnabled}
          onChange={toggleChange}
        />
        {label}
      </label>
    </div>
  );
};

export default BaseCheckbox;
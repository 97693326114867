import { useEffect } from "react";
import amplitude from "amplitude-js";
import config from "config";

const AMPLITUDE_API_KEY = config.token.amplitude;

const initAmplitude = () => {
  amplitude.getInstance().init(AMPLITUDE_API_KEY);
};

export const useAmplitude = () => {
  useEffect(() => {
    initAmplitude();
  }, []);
};

const camelCaseToCapitalize = (str) =>
  str.replace(/[A-Z]/g, (letter, index) =>
    index === 0 ? letter.toLowerCase() : ` ${letter.toLowerCase()}`
  );

export const formatCapitalizedParams = (params) => {
  if (!params) return undefined;
  const formattedParams = {};
  Object.entries(params).forEach(([paramKey, paramValue]) => {
    const newKey = camelCaseToCapitalize(paramKey);
    formattedParams[newKey] = paramValue;
  });
  return formattedParams;
};

export const logAmplitudeEvent = (eventName = "", properties = {}) => {
  const formattedParams = formatCapitalizedParams(properties);
  amplitude.getInstance().logEvent(eventName, formattedParams);
};

export enum Metrics {
  HOME_PAGE_OPENED = "ONBTN Home Page Opened",
  CONTINUE_BUTTON_TAPPED = "ONBTN Merchant Register Button Tapped",
  PAYWAY_INTEGRATION_TAPPED = "ONBTN Payway Credentials Integrate Button Tapped",
  PAYWAY_INTEGRATION_HELP_TAPPED = "ONBTN Payway Credentials Help Button Tapped",
  SET_INSTALLMENTS_BUTTON_TAPPED = "ONBTN Set Installments Button Tapped",
  SEE_TRANSACTIONS_BUTTON_TAPPED = "ONBTN See Transactions Button Tapped",
  FINANCIAL_COSTS_INFO_BUTTON_TAPPED = "ONBTN Financial Costs Info Button Tapped",
  AHORA_PLANS_INFO_BUTTON_TAPPED = "ONBTN Ahora Plans Info Button Tapped",
  CONFIRM_INSTALLMENTS_BUTTON_TAPPED = "ONBTN Confirm Installments Button Tapped",
  INSTALLMENTS_CONFIG_SUCCEEDED = "ONBTN Installments Config Succeeded",
  INSTALLMENTS_CONFIG_EXPIRED = "ONBTN Installments Configuration Expired",
}

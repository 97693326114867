import { TInitialState } from "./contextTypes";

type TAction =
  | { type: "TOGGLE_MODAL", payload: TInitialState }


const contextReducer = (state: TInitialState, action: TAction): TInitialState => {

  switch (action.type) {
    case "TOGGLE_MODAL":
      return {
        ...state,
        isVisible: action.payload.isVisible,
        type: action.payload.type,
        message: action.payload.message,
      };



    default:
      return state;
  }

};

export default contextReducer;

import styles from "./baseSelect.module.scss";
import Select from "react-select";

type TBaseInput = {
  label?: string,
  options?: { label: string, value: string }[],
  onChange?: any,
  placeholder?: string,
  className?: string
  error?: string | boolean,
  info?: string,
  name?: string,
  success?: boolean,
  defaultValue?: { label: string; value: string; };
  maxMenuHeight?: number;
}

const BaseSelect: React.FC<TBaseInput> = ({
  options = [],
  label = "",
  placeholder = "",
  className,
  error = false,
  name,
  info,
  success = false,
  defaultValue,
  maxMenuHeight = 95 
}) => {


  return (
    <div className={styles.input}>
      {label &&
        (<label className={styles.input__label}> {label} </label>)
      }
      <div className={styles.input__base}>

        <Select
          defaultValue={defaultValue || undefined}
          isSearchable={false}
          isClearable={false}
          options={options}
          maxMenuHeight={maxMenuHeight}
          name={name}
          placeholder={placeholder}
          className={`${styles.input__input} ${(error && !success) && styles.input__error}  ${className}  `}
        />

      </div>
      {info && !error && (
        <div className={styles.info}>{info}</div>
      )}

      {error && (
        <div className={styles.input__error_text}>{error}</div>
      )}

    </div>

  );
};

export default BaseSelect;
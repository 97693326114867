import { TDataStructure } from "./types";

const INSTALLMENTS_VALUES_MAPPER = {
  "threeInstallments": 3,
  "sixInstallments": 6,
  "twelveInstallments": 12,
  "eighteenInstallments": 18,
  "twentyFourInstallments": 24,
};

const INSTALLMENTS_AHORA_WITH_INTEREST = {
  "3":  {
    "tna": 0.42,
    "tem": 0,
    "coefficient": 1.076
  },
  "6": {
    "tna": 0.42,
    "tem": 0,
    "coefficient": 1.1534
  },
  "12": {
    "tna": 0.49,
    "tem": 0,
    "coefficient": 1.3186
  },
  "18": {
    "tna": 0.49,
    "tem": 0,
    "coefficient": 1.497
  },
  "24": {
    "tna": 0.49,
    "tem": 0,
    "coefficient": 1.6886      
  }
};

const INSTALLMENTS_AHORA_WITHOUT_INTEREST = {
  "tna": 0,
  "tem": 0,
  "coefficient": 1
};

const installmentsErrorMessage = "Debe seleccionar una opción de financiamiento";

const generateInstallmentsBody = (value: FormDataEntryValue, keyParsed: number, initialDataStructure: TDataStructure) => {
  switch (value) {

    case "noInstallments":
      break;

    case "installments":
      initialDataStructure.installments.push(keyParsed);
      break;

    case "installmentsWithoutInterest":
      initialDataStructure.installmentsWithoutInterest.push(keyParsed);
      break;

    case "installmentsAhora":
      
      switch (keyParsed) {
        case 3:
          initialDataStructure.installmentsAhora = {
            ...initialDataStructure.installmentsAhora,
            [`${keyParsed}`]: INSTALLMENTS_AHORA_WITH_INTEREST["3"]
          };
          break;
          case 6:
          initialDataStructure.installmentsAhora = {
            ...initialDataStructure.installmentsAhora,
            [`${keyParsed}`]: INSTALLMENTS_AHORA_WITH_INTEREST["6"]
          };
          break;
          case 12:
          initialDataStructure.installmentsAhora = {
            ...initialDataStructure.installmentsAhora,
            [`${keyParsed}`]: INSTALLMENTS_AHORA_WITH_INTEREST["12"]
          };
          break;
          case 18:
          initialDataStructure.installmentsAhora = {
            ...initialDataStructure.installmentsAhora,
            [`${keyParsed}`]: INSTALLMENTS_AHORA_WITH_INTEREST["18"]
          };
          break;
          case 24:
          initialDataStructure.installmentsAhora = {
            ...initialDataStructure.installmentsAhora,
            [`${keyParsed}`]: INSTALLMENTS_AHORA_WITH_INTEREST["24"]
          };
          break;
        default:
          break;
      }
      break;

    case "installmentsAhoraWithoutInterest":
      initialDataStructure.installmentsAhora = {
        ...initialDataStructure.installmentsAhora,
        [`${keyParsed}`]: INSTALLMENTS_AHORA_WITHOUT_INTEREST
      };
      break;
    default:
      break;
  }
};

const parseNames = (name: string) => INSTALLMENTS_VALUES_MAPPER[name];

export {
  generateInstallmentsBody,
  installmentsErrorMessage,
  parseNames,
};
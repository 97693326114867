import { TMerchant, TPaymentMethod, TResponseMerchant } from "../context/StateContext/contextTypes";
import provincies from "./provincies.json";
import CryptoJS from "crypto-js";
import config from "config";

const objIsEmpty = (obj: any) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key))
      return false;
  }
  return true;
};

const parseMerchantData = (merchant: TMerchant): TResponseMerchant => {
  return {
    "name": merchant.name,
    "integrationType": "TIENDA_NUBE",
    "token": merchant.token,
    "userId": merchant.userId,
    "stores": [
      {
        "name": merchant.name,
        "storeName": merchant.storeName,
        "email": merchant.email,
        "cuit": merchant.cuit,
        "city": merchant.city,
        "mcc": "5969",
        "phoneNumber": merchant.mobile
      }
    ]

  };
};

const parseDataToPaymentMethod = (merchant): TPaymentMethod => {

  return {
    "acquirer": "DECIDIR_PLUS",
    "credentials": {
      "publicKey": merchant.publicKey,
      "privateKey": merchant.privateKey
    },
    "externalStoreId": merchant.idSite,
    "storeId": merchant.storeId || merchant.stores[0].id,
    "installments": [1],
    "installmentsWithoutInterest": [],
    "installmentsAhora": {},
    "user_id": merchant.userId,
  };
};


const decrypt = (payload) => {
  const reb64 = CryptoJS.enc.Hex.parse(payload);
  const bytes = reb64.toString(CryptoJS.enc.Base64);
  const decrypty = CryptoJS.AES.decrypt(bytes, config.token.emailPhrase);
  return decrypty.toString(CryptoJS.enc.Utf8);
};

const dataProvincies = provincies.provincias.map(province => {
  return {
    label: province.nombre_completo,
    value: province.nombre
  };
}).sort((a, b) => {
  const fa = a.value.toLowerCase(),
    fb = b.value.toLowerCase();

  if (fa < fb) {
    return -1;
  }
  if (fa > fb) {
    return 1;
  }
  return 0;
});

const getFinancingOptions = (installments: number) => {
  return [
    {label: `No ofrecer ${installments} cuotas`, value: `noInstallments`},
    {label: `${installments} cuotas CON interés`, value: `installments`},
    {label: `${installments} cuotas SIN interés`, value: `installmentsWithoutInterest`},
    {label: `Plan Ahora ${installments} CON interés`, value: `installmentsAhora`},
    {label: `Plan Ahora ${installments} SIN interés`, value: `installmentsAhoraWithoutInterest`},
  ];
};

export {
  decrypt,
  objIsEmpty,
  parseMerchantData,
  parseDataToPaymentMethod,
  dataProvincies,
  getFinancingOptions
};
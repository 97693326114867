
import axios, { AxiosRequestHeaders } from "axios";

export type TBody = BodyInit | Record<string, unknown>;
export type TResponse = {
  data?: any;
  status: number;
  statusText?: string;
};

export type TAxiosError = {

  response: {
    data: { message: string },
    statusText?: string,
    status: number
  }

}

const get = async (url: string, headers?: AxiosRequestHeaders): Promise<TResponse> => {
  try {
    const config = {
      headers,
    };

    //send headers and request to URL
    const response = await axios.get(url, config);
    const { status, data } = response;

    return { data, status };

  } catch (err) {
    const { response } = err as TAxiosError;
    const status = response?.status;
    const statusText = response?.data.message ? response?.data.message : "";
    return {
      status,
      statusText
    };
  }

};


const post = async (url: string, body: TBody, header?: AxiosRequestHeaders): Promise<TResponse> => {
  try {
    const headers = {
      ...header,
      "Content-Type": "application/json",
    };
    //send headers and request to URL
    const response = await axios({
      method: "post",
      url: url,
      data: body,
      headers: { ...headers }
    });
    const { data, status } = response;

    return { data, status };
  } catch (err) {
    console.log(err);

    const { response } = err as TAxiosError;
    const status = response?.status;
    const statusText = response?.data.message || response?.statusText;
    return {
      status,
      statusText
    };
  }
};

const put = async (url: string, body: TBody): Promise<TResponse> => {
  try {
    const response = await axios({
      method: "put",
      url: url,
      data: body,
    });
    const { data, status } = response;
    return { data, status };
  } catch (err) {
    const { response } = err as TAxiosError;
    const status = response?.status;
    const statusText = response?.data.message || response?.statusText;
    return {
      status,
      statusText
    };
  }
};

const request = { get, post, put };

export default request;

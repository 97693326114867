import { logAmplitudeEvent } from "hooks/useAmplitude";
import { Metrics } from "metrics/events";
import styles from "../installments.module.scss";

import { installmentsFormInfo } from "./utils";


export const InstallmentsTitle = () => {

  const { title, firstDesciption, secondDesciption } = installmentsFormInfo.installmentsDescription;

  const handleClickFinancialCosts = () => {
    logAmplitudeEvent(Metrics.FINANCIAL_COSTS_INFO_BUTTON_TAPPED);
    window.location.href = firstDesciption.link.href;
  };

  const handleClickAhoraPlansInfo = () => {
    logAmplitudeEvent(Metrics.AHORA_PLANS_INFO_BUTTON_TAPPED);
    window.location.href = secondDesciption.link.href;
  };

  return (
    <div className={styles.body__info}>
      <div className={`sub-title`}>
        {title}
      </div>
      <div className={`description-container description`}>
        {firstDesciption.text}
        <span className={`description-link`}>
          <a onClick={handleClickFinancialCosts} target="__blank">
            {firstDesciption.link.text}
          </a>
        </span>
      </div>
      <div className={`description-container description`}>
        {secondDesciption.text}
        <span className={`description-link`}>
          <a onClick={handleClickAhoraPlansInfo} target="__blank">
            {secondDesciption.link.text}
          </a>
        </span>
      </div>
    </div>
  );
};

import { ReactNode } from "react";

import BaseImage from "../../baseComponents/BaseImage";
import styles from "./default.module.scss";

const LayoutDefault: React.FC<{ children: ReactNode }> = ({ children }) => {

    return (
        <div className={`${styles.container}`}>
            <main>
                <div className={styles.container__left}>
                    <div className={styles.container__left_header} />

                    <div className={styles.container__left_bottom}>
                        <BaseImage image="svg-people" width={300} height={"auto"} />
                    </div>
                </div>
                <div className={styles.container__right}>
                    {children}

                    <div className={styles.container__right_footer}>
                        Si tenés alguna duda, visitá nuestro&nbsp;
                        <a
                            href="https://ayuda.modo.com.ar/support/solutions/folders/66000421809"
                            target={"_blank"}
                            className="text-secondary fw-600" rel="noreferrer">
                            centro de ayuda
                        </a>
                    </div>
                </div>
            </main>

        </div>
    );
};


export default LayoutDefault;
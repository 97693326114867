import { TInitialState, TMerchant, TResponseMerchant } from "./contextTypes";

type TAction =
  | { type: "BEGIN_OPERATION", payload: boolean }
  | { type: "SUCCESS_OPERATION", payload: boolean }
  | { type: "ADD_MERCHANT", payload: TResponseMerchant }
  | { type: "PUT_MERCHANT", payload: TMerchant }
  | { type: "ERROR", payload: string }

const contextReducer = (state: TInitialState, action: TAction): TInitialState => {

  switch (action.type) {
    case "BEGIN_OPERATION":
      return {
        ...state,
        isLoading: action.payload,
        isError: false,
        isSuccess: false,
        errorMessage: ""
      };

    case "SUCCESS_OPERATION":
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };

    case "ADD_MERCHANT":
      return {
        ...state,
        merchant: { ...state.merchant, ...action.payload },
        isSuccess: true,
        isLoading: false,
        isError: false,
        errorMessage: ""
      };

    case "PUT_MERCHANT":
      return {
        ...state,
        merchant: { ...state.merchant, ...action.payload },
        isLoading: false,
        isSuccess: true,
        isError: false,
        errorMessage: "",
        jwe: action.payload.jweCredentials
      };

    case "ERROR":
      return {
        ...state,
        isSuccess: false,
        isLoading: false,
        isError: true,
        errorMessage: action.payload
      };

    default:
      return state;
  }

};

export default contextReducer;
import { TKeyString } from "./types";

const errorMessages: TKeyString = {
  "email": "Necesitamos un email valido",
  "name": "Necesitamos el nombre comercial o de fantasía",
  "storeName": "Necesitamos tu razón social o tu nombre y apellido",
  "mobile": "Necesitamos un numero de celular",
  "cuit": "Necesitamos un CUIT o DNI",
  "city": "Elegí una Provincia",
  "publicKey": "Necesitamos la Key Pública que recibiste por email",
  "privateKey": "Necesitamos la Key Privada que recibiste por email",
  "idSite": "Necesitamos el id Site que recibiste por email",
};


export {
  errorMessages
};
import { createContext } from "react";
import { TInitialState } from "./contextTypes";

type TToggle = {
  isVisible?: boolean,
  message?: string,
  type?: string
}


export type TContextProp = {
  state: TInitialState,
  toggleModal: ({ message, type, isVisible }: TToggle) => void,
}

const ModalContext = createContext<TContextProp>({} as TContextProp);


export default ModalContext;
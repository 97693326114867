import { ReactNode } from "react";
import { useModalState } from "../../context/ModalContext/ModalProvider";
import BaseButton from "../BaseButton";
import BaseIcon from "../BaseIcons";
import styles from "./baseAlert.module.scss";

type TAlert = {
  type?: string,
  message?: string
  children: ReactNode
}

const BaseAlert = ({ children }: TAlert) => {

  const { toggleModal, state } = useModalState();
  const { isVisible, message, type } = state;

  const typeButton = type === "error" ? "danger" : type;
  return (
    <div className={styles.main}>
      <div className={`${isVisible ? styles.visible : styles.noVisible} ${styles.modal}`}>
        <div className={styles.modal__content}>
          <div className={styles.modal__header}>
            <BaseIcon icon={`icon-${type}`} />
          </div>
          <div className={styles.modal__body}>
            {message}
          </div>

          <div className={styles.modal__footer}>
            <BaseButton title="Cerrar" type={typeButton} onClick={() => toggleModal({ isVisible: false })} />
          </div>

        </div>


      </div>
      <div className={`${isVisible && styles.blur}`}>
        {children}
      </div>
    </div>
  );
};


export default BaseAlert;
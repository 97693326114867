import { useEffect } from "react";
import LayoutV2 from "layouts/V2";

import styles from "./installments-expired.module.scss";
import BaseImage from "baseComponents/BaseImage";
import { logAmplitudeEvent } from "hooks/useAmplitude";
import { Metrics } from "metrics/events";

const InstallmentsExpired = () => {

  useEffect(() => {
    logAmplitudeEvent(Metrics.INSTALLMENTS_CONFIG_EXPIRED);
  }, []);
  
  return (
    <LayoutV2>

      <div className={styles.body}>
        <div className={styles.body__error}>
          <BaseImage image={"icon-warning-black"} width={80} height={80} />
        </div>

        <div className={styles.box}>
          <div className={`${styles.box__title} title`}>
            ¡El link ha expirado!
          </div>
          <div>Si queres cambiar la configuración de tus cuotas podés seguir la siguiente guía</div>
        </div>

        <div className={styles.body__btn__centered}>
          <a href="https://ayuda.modo.com.ar/support/solutions/articles/66000505709-configuraci%C3%B3n-de-cuotas-planes-ahora-y-medios-de-pago-para-tiendanube" className={styles.box__btn}>
            <span>Guía para configurar cuotas</span>
          </a>
        </div>

      </div>
    </LayoutV2 >
  );
};

export default InstallmentsExpired;
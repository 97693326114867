const parseMessageError = (message): string => {
  switch (message) {
    case "Error al obtener el usuario":
      return "Error al obtener el usuario, vuelva a instalar el Boton en Tienda Nube";

    case "Error 2":
      return "Error";

    default:
      return message;

  }
};


export {
  parseMessageError
};
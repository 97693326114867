import { FC, ReactNode, useContext } from "react";
import GlobalContext from "./GlobalContext";
import useContextFunction from "./ContextFunctions";

const StateProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { createMiddleman, putMerchant, sendInstallmentsData, getMerchantDataFromJwe, state, setConfigureKey, sendEmailPayway, sendEmailSuccess, updateStateData } = useContextFunction();
  return (
    <GlobalContext.Provider value={{
      state,
      createMiddleman,
      putMerchant,
      setConfigureKey,
      sendEmailPayway,
      sendEmailSuccess,
      updateStateData,
      sendInstallmentsData,
      getMerchantDataFromJwe
    }}>
      {children}
    </GlobalContext.Provider>
  );
};

export default StateProvider;

export const useMerchant = () => useContext(GlobalContext);

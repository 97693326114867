
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";


import CredentialsSuccess from "./CredentialsSuccess";
import FormMerchants from "./RegisterMerchant";

import Home from "./Home";

import CredentialsRegister from "./CreateAccountPayway";
import StateProvider from "../context/StateContext/StateProvider";
import BaseAlert from "../baseComponents/BaseAlert";
import ModalProvider from "../context/ModalContext/ModalProvider";
import ConfigureCredentials from "./ConfigureCredentials";
import InstallmentsForm from "./Installments/InstallmentsForm";
import InstallmentsSuccess from "./Installments/InstallmentsSuccess";
import InstallmentsExpired from "./Installments/InstallmentsExpired";
import { useAmplitude } from "hooks/useAmplitude";


const Principal = () => {
  useAmplitude();
  return (
    <BrowserRouter>
      <StateProvider>
        <ModalProvider>
          <BaseAlert>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/register" element={<FormMerchants />} />
              <Route path="/configure-credentials" element={<ConfigureCredentials />} />

              <Route path="/installments-configuration/:jwe" element={<InstallmentsForm />} />
              <Route path="/installments-success" element={<InstallmentsSuccess />} />
              <Route path="/installments-expired" element={<InstallmentsExpired />} />

              <Route path="/register-credentials" element={<CredentialsRegister />} />
              <Route path="/credentials-success" element={<CredentialsSuccess />} />
            </Routes>
          </BaseAlert>
        </ModalProvider>
      </StateProvider>
    </BrowserRouter >
  );
};

export default Principal;

import { createContext } from "react";
import { TCredentialForm, TInitialState, TInstallmentsBody, TIssuerNameProps, TMerchant } from "./contextTypes";


export type TContextProp = {
  state: TInitialState,
  createMiddleman: (merchant: TMerchant) => void,
  putMerchant: (id: string | number) => void,
  setConfigureKey: (payload: TCredentialForm) => void,
  sendEmailPayway: () => void,
  sendEmailSuccess: (jwe: string | undefined) => void,
  updateStateData: (payload: any) => void,
  sendInstallmentsData: (jwe: string | undefined, installmentsBody: TInstallmentsBody, blockedIssuersBody: TIssuerNameProps) => void;
  getMerchantDataFromJwe: (jwe: string | undefined) => any;
}

const GlobalContext = createContext<TContextProp>({} as TContextProp);


export default GlobalContext;
import React from "react";

import BaseIcon from "../BaseIcons";

import styles from "./baseLoading.module.css";

type TBaseLoading = {
  isLoading?: boolean
  width?: number
  height?: number
}

const BaseLoading: React.FC<TBaseLoading> = ({ isLoading, width = 30, height = 30 }) => {
  return (
    <div className={styles.loading}>
      {isLoading && <BaseIcon icon="spinner" width={width} height={height} alt="loading..." />}
    </div>
  );
};

export default BaseLoading;

import BaseButton from "../../baseComponents/BaseButton";
import BaseInput from "../../baseComponents/BaseInput";
import BaseInputPhone from "../../baseComponents/BaseInputPhone";
import BaseSelect from "../../baseComponents/BaseSelect";
import styles from "./form.module.scss";
import useForm from "../../hooks/useForm";
import { useMerchant } from "../../context/StateContext/StateProvider";
import LayoutV2 from "layouts/V2";
import { dataProvincies } from "utils/utilities";
import { Metrics } from "metrics/events";
import { logAmplitudeEvent } from "hooks/useAmplitude";


const RegisterMerchant = () => {
  const { state: { merchant, isError, errorMessage, isLoading }, createMiddleman } = useMerchant();

  const handleForm = (payload: any) => {
    const addData = {
      ...payload,
      userId: merchant.userId,
      token: merchant.token
    };
    logAmplitudeEvent(Metrics.CONTINUE_BUTTON_TAPPED);
    createMiddleman(addData);
  };

  const { errors, handleSubmit, success } = useForm(handleForm);

  return (
    <LayoutV2>

      <div className={styles.body}>
        <div className={`${styles.body__title} title`}>
          Completá los datos de tu comercio
        </div>

        <form className={styles.body__form} onSubmit={handleSubmit}>
          <div>

            <BaseInput
              label={"Razón social o tu nombre y apellido"}
              name={"storeName"}
              type={"text"}
              error={errors.storeName}
              placeholder={"Ej: Negocio S.A."}
              info={"Es el nombre legal de tu comercio o tu nombre completo"}
              success={!!success.storeName}

            />

            <BaseInput
              label={"Nombre comercial o de fantasía"}
              name={"name"}
              error={errors.name}
              type={"text"}
              maxLength={25}
              placeholder={"Ej: Negocio zapatos online"}
              info={"Es el nombre que verán los usuarios que paguen con MODO (max 25 caracteres)"}
              success={!!success.name}
            />

            <BaseInput
              label={"CUIT o DNI del titular del comercio"}
              type={"number"}
              min={6}
              name={"cuit"}
              error={errors.cuit}
              placeholder={"Ej: 279567480"}
              info={"Sin puntos, comas o guión"}
              success={!!success.cuit}
            />


            <BaseInput
              label={"Mail de tu comercio "}
              type={"email"}
              placeholder={"Ej: contacto@contacto.com"}
              info={"Es el correo a donde te enviaremos  notificaciones"}
              name={"email"}
              error={errors.email}
              success={!!success.email}
              value={merchant?.email || ""}
            />

            <BaseInputPhone
              label={"Celular Personal"}
              placeholder={"Ej: 1132277777"}
              info={"Ingresá el número sin guiones ni espacios"}
              name={"mobile"}
              error={errors.mobile}
              success={!!success.mobile}
              maxLength={30}
            />

            <BaseSelect
              error={errors.city}
              name={"city"}
              label={"Provincia"}
              options={dataProvincies}
              placeholder={"Ej: Río Negro"}
              success={!!success.city}
              info={"Es la ubicación de tu negocio "}
            />

            {isError && (
              <div className={styles.body__error}>
                {errorMessage}
              </div>
            )}
          </div>

          <div className={styles.body__button}>
            <BaseButton title="Continuar" isLoading={isLoading} typeButton="submit" />
          </div>

        </form>

      </div>
    </LayoutV2>
  );
};

export default RegisterMerchant;
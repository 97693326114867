import { FC, useEffect } from "react";
import BaseButton from "baseComponents/BaseButton";
import BaseImage from "baseComponents/BaseImage";
import LayoutV2 from "layouts/V2";
import styles from "./home.module.scss";

import { useLocation } from "react-router-dom";

import { useModalState } from "../../context/ModalContext/ModalProvider";
import { useMerchant } from "../../context/StateContext/StateProvider";

import { logAmplitudeEvent } from "hooks/useAmplitude";
import { Metrics } from "metrics/events";

const Home: FC = () => {
  const useParams = useLocation().search;
  const userId = new URLSearchParams(useParams).get("user_id") as string;
  const { toggleModal } = useModalState();
  const { putMerchant, state: { isError, errorMessage, merchant } } = useMerchant();

  useEffect(() => {
    putMerchant(userId);
  }, [userId]);

  useEffect(() => {
    if (merchant && "email" in merchant) {
      logAmplitudeEvent(Metrics.HOME_PAGE_OPENED, {
        email: merchant.email!, 
        userId: userId 
      });
    }
  }, [merchant]);

  useEffect(() => {
    if (isError) {

      toggleModal({
        message: errorMessage,
        type: "error",
        isVisible: isError
      });
    }
  }, [isError]);

  return (
    <LayoutV2 isHome>

      <div className={styles.container}>
        <BaseImage style={styles.image_xs} image="image-welcome" />

        <div className={styles.body}>

          <div className="font-extra-big fw-bold mb-5">Conectá <span className="text-dark-secondary">MODO</span> a tu Tiendanube:</div>

          <div className="fw-bold mb-4 align-items-center">
            <span className="text-dark-secondary font-big mr-4"> 1 </span>
            <span className="font-medium">Completá los datos de tu comercio</span>
          </div>

          <div className={styles.content}>

            <span className="text-dark-secondary font-big mr-4"> 2 </span>
            <div className="font-medium mb-3" >
              <span> Configurá tu cuenta Payway </span>
              <span className="fw-normal xs-hidden">
                (Trabajamos junto a Payway para ofrecer una mejor experiencia de pago)
                <p className="font-medium fw-bold mt-3">¡Te vamos a ayudar a hacerlo!</p>
              </span>
            </div>
          </div>

          <BaseImage style={styles.image} image="image-welcome" width={"auto"} height={"50%"} />
        </div>

        <div className={styles.button}>
          <BaseButton title="Comenzar" toLink="/register" />
        </div>
      </div>
    </LayoutV2 >
  );
};


export default Home;

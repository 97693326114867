
import { useNavigate } from "react-router-dom";
import { useMerchant } from "context/StateContext/StateProvider";
import LayoutV2 from "layouts/V2";
import BaseImage from "../../baseComponents/BaseImage";
import styles from "./credentials.module.scss";
import { logAmplitudeEvent } from "hooks/useAmplitude";
import { Metrics } from "metrics/events";


const CredentialsSuccess = () => {

  const {state} = useMerchant();
  const navigate = useNavigate();

  const handleClickInstallments = () => {
    logAmplitudeEvent(Metrics.SET_INSTALLMENTS_BUTTON_TAPPED);
    navigate(`/installments-configuration/${state.jwe}`);
  };

  const handleClickTransactions = () => {
    logAmplitudeEvent(Metrics.SEE_TRANSACTIONS_BUTTON_TAPPED);
    window.location.href = "https://ventasonline.payway.com.ar/portal/login";
  };

  return (
    <LayoutV2>

      <div className={styles.body}>
        <div className={styles.body__success}>
          <BaseImage image={"icon-success"} width={80} height={80} />
        </div>

        <div className={styles.box}>
          <div className={styles.box__image}>
            <BaseImage image="image-people-warn" width={0} height={0} />
          </div>
          <div className={`${styles.box__title} title`}>
            ¡Ya tenés el botón de pago MODO en tu tienda!
          </div>

          <div>Configurá las cuotas disponibles para tu tienda</div>

          <a className={`${styles.box__btn}`} onClick={handleClickInstallments}>
            <span  > Configurar cuotas</span>
          </a>

          <div>Seguí tus transacciones en Payway ventas online</div>

          <a className={`${styles.box__btn} `} onClick={handleClickTransactions}>
            <span >Ver transacciones </span>
          </a>

        </div>


        <div className={`${styles.body__notice}`}>
          <span className="fw-bold">¿No podés hacerlo ahora?</span> No te preocupes. <span className="fw-bold">Desde MODO te mandamos un email</span> para que lo hagas después.
        </div>

      </div>


    </LayoutV2 >
  );
};


export default CredentialsSuccess;
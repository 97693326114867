import { useEffect } from "react";
import LayoutV2 from "layouts/V2";
import { logAmplitudeEvent } from "hooks/useAmplitude";
import { Metrics } from "metrics/events";

import styles from "./installments-success.module.scss";
import BaseImage from "baseComponents/BaseImage";

const InstallmentsSuccess = () => {

  useEffect(() => {
    logAmplitudeEvent(Metrics.INSTALLMENTS_CONFIG_SUCCEEDED);
  }, []);
  
  return (
    <LayoutV2>

      <div className={styles.body}>
        <div className={styles.body__success}>
          <BaseImage image={"icon-success"} width={80} height={80} />
        </div>

        <div className={styles.box}>
          <div className={styles.box__image}>
            <BaseImage image="image-people-warn" width={0} height={0} />
          </div>
          <div className={`${styles.box__title} title`}>
            ¡Ya configuraste tus cuotas!
          </div>

          <div>Seguí tus transacciones en Payway ventas online</div>

          <a href="https://ventasonline.payway.com.ar/portal/login" className={`${styles.box__btn} `}>
            <span >Ver transacciones </span>
          </a>

        </div>

        <div className={`${styles.body__notice}`}>
          <span className="fw-bold">¿No podés hacerlo ahora?</span> No te preocupes. <span className="fw-bold">Desde MODO te mandamos un email</span> para que lo hagas después.
        </div>

      </div>
    </LayoutV2 >
  );
};

export default InstallmentsSuccess;
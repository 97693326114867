import { useMerchant } from "context/StateContext/StateProvider";
import BaseButton from "../../baseComponents/BaseButton";
import BaseImage from "../../baseComponents/BaseImage";
import LayoutDefault from "../../layouts/LayoutDefault";
import styles from "./createPayway.module.scss";

const RegisterDecidir = () => {
  const { sendEmailPayway } = useMerchant();

  const handleNavigation = () => {
    sendEmailPayway();
    window.open("https://live.decidir.com/onboarding/", "_blank");
  };

  return (
    <LayoutDefault>

      <div className={styles.logo}>
        <BaseImage image={"img-modo-comercio"} />
      </div>

      <div className={styles.body}>
        <div className={`${styles.body__title} title`}>
          Creá una cuenta en Payway, a la vuelta te esperamos 🙌
        </div>

        <div className={`general`}>
          <span>Cuando hayas creado la cuenta, recibirás en los próximos días <b>2 mails con claves de Payway</b> : </span>
          <ul>
            <li> Mail  de &quot;datos de prueba&quot;, este no lo usaremos.</li>
            <li> Mail de <b>&quot;datos de producción&quot; con 3 claves</b> que te recomendamos tener a mano para el próximo paso. </li>
          </ul>
          <span> Te dejamos un ejemplo de las 3 claves que necesitás: </span>
          <div className="fs-small">
            <span></span>
            <ul>
              <li>API Key Pública: xxxxxxxxxxxxxxx3bd30ec2f9bf8095a</li>
              <li>API Key Privada: xxxxxxxxxxxxxxxb5fbd5225e37372711f</li>
              <li>ID site: xxxx3178</li>
            </ul>
          </div>

          <span>Además, <b>recibirás un mail de MODO</b> que te permitirá continuar con la configuración del botón de pago,
            una vez que recibas los mails con las claves de Payway.</span>

          <div className={styles.body__button}>
            <BaseButton title="Ir a Payway a crear cuenta" onClick={handleNavigation} />
          </div>
        </div>
      </div>

    </LayoutDefault>
  );
};


export default RegisterDecidir;
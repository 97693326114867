import styles from "../installments.module.scss";

import { installmentsFormInfo } from "./utils";

export const BlockedIssuersTitle = () => {
  return (
    <div className={styles.body__info}>
    <div className={`sub-title`}>
      {installmentsFormInfo.paymentIssuersTitle}
    </div>
    <div className={`description`} dangerouslySetInnerHTML={ {__html: installmentsFormInfo.paymentIssuersDescription} }>
    </div>
  </div>
  );
};

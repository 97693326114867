import { useState } from "react";
import { isValidInput } from "../../utils/validations";
import { TBlockedIssuers, TBlockedIssuersOptions, TDataStructure, TFormInstallments, TKeyStringFunction } from "./types";
import { generateInstallmentsBody, parseNames } from "./util";

const useInstallments = (callback) => {

  const installmentsBody: TFormInstallments = { 
    threeInstallments: "",
    sixInstallments: "",
    twelveInstallments: "",
    eighteenInstallments: "",
    twentyFourInstallments: "",
  };

  const blockedIssuersBody: TBlockedIssuers = { 
    cardFilter: {
       issuer_name: {
          type: "block",
          value: []
       }
    } 
   };
  
  const [success, setSuccess] = useState<any>(installmentsBody);
  const [values, setValues] = useState<any>(installmentsBody);
  const [errors, setErrors] = useState<any>({});

  const handleValidator = (name: string, value: string) => {
    const validator: TKeyStringFunction = {
      "threeInstallments": () => isValidInput(value),
      "sixInstallments": () => isValidInput(value),
      "twelveInstallments": () => isValidInput(value),
      "eighteenInstallments": () => isValidInput(value),
      "twentyFourInstallments": () => isValidInput(value),
      "american_express": () => isValidInput(value),
      "cabal": () => isValidInput(value),
      "maestro": () => isValidInput(value),
      "naranja": () => isValidInput(value),
      "diners_club": () => isValidInput(value),
      "confiable": () => isValidInput(value),
    };
    return validator[name](value);
  };


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const { name, value } = event.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (event: React.FormEvent, blockedIssuers: TBlockedIssuersOptions) => {
    event.preventDefault();

    const formData = new FormData(event.target as HTMLFormElement);

    const objectData = {};

    const installmentsBody: TDataStructure = {
      installments: [1],
      installmentsWithoutInterest: [],
      installmentsAhora: {},
    };
    
    for (const [key, value] of formData.entries()) {
      objectData[key] = value;

      const keyParsed: number = parseNames(key);

      generateInstallmentsBody(value, keyParsed, installmentsBody);
    }

    for (const [key, value] of Object.entries(blockedIssuers)) {
      if (value) blockedIssuersBody.cardFilter.issuer_name.value.push(key);
    }

    let error = {};
    let successValue = {};

    Object.entries(objectData).forEach(([key, value]) => {
      const isValid = handleValidator(key, value as string);
      if (!isValid) {       
        error = {
          ...error,
          [key]: "Debe seleccionar una opción de financiamiento"
        };
      } else {
        // @ts-ignore
        // eslint-disable-next-line
        const { [key]: val, ...rest } = error;
        error = { ...rest };

        successValue = {
          ...successValue,
          [key]: value
        };
      }
    });

    setSuccess(successValue);
    setErrors(error);  

    if (Object.keys(error).length === 0 && Object.keys(values).length !== 0) {
      callback(installmentsBody, blockedIssuersBody);
    }
  };


  return {
    values,
    errors,
    handleValidator,
    handleChange,
    handleSubmit,
    success
  };
};

export default useInstallments;

import { TGenerateEnv } from "./types";

const generateEnv = (): TGenerateEnv => {
  const missingKeys: string[] = [];

  const getEnvVar = (key: string, defaultValue?: string): string => {
    if (!process.env[key] && defaultValue === undefined) {
      missingKeys.push(key);
    }
    return (process.env[key] || defaultValue) as string;
  };
  const config = {
    enviroment: getEnvVar("REACT_APP_ENV"),
    urlFaqModo: getEnvVar("REACT_APP_FAQ_MODO"),
    api: {
      ecommerceApi: `${getEnvVar("REACT_APP_ECOMMERCE_API")}`,
      getMerchant: `${getEnvVar("REACT_APP_ECOMMERCE_API")}/api/modo/onboarding/merchant`,
      getInstallmentsAndIssuersByJwe: `${getEnvVar("REACT_APP_ECOMMERCE_API")}/api/modo/onboarding/store/payment-method/payment-config`,
      updateInstallmentsAndBlockedIssuers: `${getEnvVar("REACT_APP_ECOMMERCE_API")}/api/modo/onboarding/payment-config`,
      createMiddleman: `${getEnvVar("REACT_APP_ECOMMERCE_API")}/api/modo/onboarding/create-middleman`,
      savePayment: `${getEnvVar("REACT_APP_ECOMMERCE_API")}/api/modo/onboarding/payment-method`,
      sendEmail: `${getEnvVar("REACT_APP_ECOMMERCE_API")}/api/modo/email`,
      validateCredentials: `${getEnvVar("REACT_APP_ECOMMERCE_API")}/api/modo/onboarding/validate`,
    },
    token: {
      amplitude: getEnvVar("REACT_APP_AMPLITUDE_KEY"),
      emailPhrase: getEnvVar("REACT_APP_SECRET_PHRASE"),
    },
    tiendaNube: {
      apiValidateToken: getEnvVar("REACT_APP_TN_URL_VALIDATE_TOKEN"),
      urlAdminStore: getEnvVar("REACT_APP_URL_ADMIN_TN"),
    }
  };

  if (missingKeys.length) {
    throw new Error(`The following environment variables are missing: ${missingKeys}`);
  }

  if (process.env.REACT_APP_ENV === "local") {
    config.api.ecommerceApi = "http://localhost:3000/ecommerce";
  }
  return config;
};


export default generateEnv();
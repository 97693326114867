import Spinner from "./spinner.gif";
import LogoM from "./logo-m.png";
import ModoComercio from "./modo-comercio.png";
import headerEmailPayway from "./header-email-payway.jpg";
import bodyEmailPayway from "./body-email-payway.jpg";

export type Map = {
  [key: string]: string | undefined
}

const Images: Map = {
  "spinner": Spinner,
  "logoM": LogoM,
  "img-modo-comercio": ModoComercio,
  "img-body-email-payway": bodyEmailPayway,
  "img-header-email-payway": headerEmailPayway
};



export default Images;

import { useState } from "react";
import { isValidInput, isValidEmail, isValidPhone, isValidName } from "../../utils/validations";
import { TForm, TKeyStringFunction } from "./types";
import { errorMessages } from "./util";

const useForm = (callback: any) => {

  const initialState: TForm = {
    email: "",
    name: "",
    mobile: "",
    cuit: "",
    storeName: "",
    city: "",
    publicKey: "",
    privateKey: "",
    idSite: ""

  };

  const [success, setSuccess] = useState(initialState);
  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState<TForm>({});


  /**
   * 
   * @param {string} name 
   * @param {string} value 
   * @description Manejador de validaciones dinamico, recibe un string como parametro, valida y retorna boolean
   * @returns boolean
   */
  const handleValidator = (name: string, value: string) => {
    const validator: TKeyStringFunction = {
      "email": () => isValidEmail(value as string),
      "name": () => isValidName(value as string),
      "mobile": () => isValidPhone(value as string),
      "cuit": () => isValidInput(value as string),
      "storeName": () => isValidInput(value as string),
      "city": () => isValidInput(value as string),
      "publicKey": () => isValidInput(value as string),
      "privateKey": () => isValidInput(value as string),
      "idSite": () => isValidInput(value as string),
    };
    return validator[name](value as string);
  };

  /**
   * 
   * @param {event} event 
   * @param {string} value 
   * @description Manejador de estados de los input, va agregando cada input al objeto values
   * @returns boolean
   */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();

    const { name, value } = event.target;

    setValues({
      ...values,
      [name]: value,
    });
  };


  const handleSubmit = (event: any) => {
    event.preventDefault();


    const formData = new FormData(event.target);

    const objectData = {};

    for (const [key, value] of formData.entries()) {
      //@ts-ignore
      objectData[key] = value;
    }

    let error = {};
    let successValue = {};

    Object.entries(objectData).forEach(([key, value]) => {
      const validar = handleValidator(key, value as string);
      if (!validar) {
        error = {
          ...error,
          [key]: errorMessages[key]
        };
      } else if (validar === "invalid_character") {
        error = {
          ...error,
          [key]: "Formato inválido. Ingresá el nombre sin &, ñ  o Ñ "
        };
      } else {
        // @ts-ignore
        // eslint-disable-next-line
        const { [key]: val, ...rest } = error;
        error = { ...rest };

        successValue = {
          ...successValue,
          [key]: value
        };
      }

    });
    setSuccess(successValue);
    setErrors(error);

    if (Object.keys(error).length === 0 && Object.keys(values).length !== 0) {
      callback(objectData);
    }
  };


  return {
    values,
    errors,
    handleChange,
    handleSubmit,
    success
  };
};

export default useForm;
import { FC, ReactNode, useContext, useReducer } from "react";
import contextReducer from "./modalReducer";
import ModalContext from "./ModalContext";
import { initialState } from "./initialState";



export const useModalState = () => useContext(ModalContext);

const ModalProvider: FC<{ children: ReactNode }> = ({ children }) => {

  const [state, dispatch] = useReducer(contextReducer, initialState);

  const toggleModal = ({ message = "", type = "", isVisible = false }) => {
    dispatch({ type: "TOGGLE_MODAL", payload: { message, type, isVisible } });
  };

  return (
    <ModalContext.Provider value={{
      state,
      toggleModal
    }}>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;


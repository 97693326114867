import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useMerchant } from "../../../context/StateContext/StateProvider";
import useInstallments from "hooks/useInstallments";
import { installmentsFormInfo, setDefaultBlockedIssuersValues, setDefaultValues } from "./utils";
import { getFinancingOptions } from "utils/utilities";
import LayoutV2 from "layouts/V2";
import BaseButton from "../../../baseComponents/BaseButton";
import BaseSelect from "../../../baseComponents/BaseSelect";
import BaseCheckbox from "baseComponents/BaseCheckbox";
import BaseLoading from "baseComponents/BaseLoading";
import { BlockedIssuersProps, DefaultBlockedIssuersState, DefaultInstallmentsState, InstallmentsProps } from "./types";
import { parseNames } from "hooks/useInstallments/util";
import { logAmplitudeEvent } from "hooks/useAmplitude";
import { Metrics } from "metrics/events";

import styles from "../installments.module.scss";
import { InstallmentsTitle } from "./InstallmentsTitle";
import { BlockedIssuersTitle } from "./BlockedIssuersTitle";

const InstallmentsForm = () => {

  const installmentsOptions = ["three","six","twelve","eighteen","twentyFour"];
  const issuersOptions = [
    {name: "american_express", label: "American Express"},
    {name: "cabal", label: "Cabal"},
    {name: "maestro", label: "Maestro"},
    {name: "naranja", label: "Naranja"},
    {name: "diners_club", label: "Diners Club"},
    {name: "confiable", label: "Confiable"},
  ];
  
  const navigate = useNavigate();
  const { state, sendInstallmentsData, getMerchantDataFromJwe } = useMerchant();
  const { jwe } = useParams();
  
  const [isLoading, setIsLoading] = useState<boolean>(false);
  
  const [defaultInstallmentsState, setDefaultInstallmentsState] = useState<DefaultInstallmentsState>({
    threeDefaultValue: undefined,
    sixDefaultValue: undefined,
    twelveDefaultValue: undefined,
    eighteenDefaultValue: undefined,
    twentyFourDefaultValue: undefined,
  });

  const [defaultBlockedIssuersState, setDefaultBlockedIssuersState] = useState<DefaultBlockedIssuersState>({
    american_express: false,
    cabal: false,
    maestro: false,
    naranja: false,
    diners_club: false,
    confiable: false,
  });

  const handleBlockedIssuersCheckboxChange = (value: boolean, field: string) => {
    setDefaultBlockedIssuersState({
      ...defaultBlockedIssuersState,
      [field]: value
    });
  };

  const handleGetData = async () => {
    setIsLoading(true);

    try {
      const response = await getMerchantDataFromJwe(jwe);
      const {installments, issuers } = response.response.data;

      if (response.success) {
        setDefaultInstallmentsState({
          ...defaultInstallmentsState,
          threeDefaultValue: setDefaultValues(installments, 3),
          sixDefaultValue: setDefaultValues(installments, 6),
          twelveDefaultValue: setDefaultValues(installments, 12),
          eighteenDefaultValue: setDefaultValues(installments, 18),
          twentyFourDefaultValue: setDefaultValues(installments, 24)
        });
        setDefaultBlockedIssuersValues(issuers, defaultBlockedIssuersState);
      } 
    } catch (error) {
      navigate("/installments-expired");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleGetData();
  }, []);

  const handleSendData = (installmentsBody: InstallmentsProps, blockedIssuersBody: BlockedIssuersProps) => {
    logAmplitudeEvent(Metrics.CONFIRM_INSTALLMENTS_BUTTON_TAPPED);
    sendInstallmentsData(jwe, installmentsBody, blockedIssuersBody);
  };

  const { errors, handleSubmit, success } = useInstallments(handleSendData);

  return (
    <LayoutV2>
      <div className={styles.body}>
        <div className={`${styles.body__title} title`}>
          {installmentsFormInfo.title}
        </div>
        <div className={styles.body__example}>
          <div className={`sub-title`}>
            {installmentsFormInfo.subtitle}
          </div>
        </div>
        <form className={styles.body__form} onSubmit={(e) => handleSubmit(e, defaultBlockedIssuersState)}>
          <InstallmentsTitle/>
          {
            isLoading ?
              <BaseLoading isLoading={true} /> :
              <>
                {
                  installmentsOptions.map( (numberOfInstallments: string) => {
                    const installmentName = `${numberOfInstallments}Installments`;
                    const installmentDefaultValue = `${numberOfInstallments}DefaultValue`;
                    const installmentInFormatNumber = parseNames(installmentName);
                    return (
                      <BaseSelect
                        key={numberOfInstallments}
                        options={getFinancingOptions(installmentInFormatNumber)}
                        name={`${numberOfInstallments}Installments`}
                        label={`${installmentInFormatNumber} cuotas`}
                        placeholder={`Ej: ${installmentInFormatNumber} cuotas SIN interés`}
                        info="Seleccioná la cantidad de cuotas"
                        error={errors[installmentName]}
                        success={!!success[installmentName]}
                        defaultValue={defaultInstallmentsState[installmentDefaultValue]}
                        maxMenuHeight={200}
                      />
                    );
                  })
                }
              </>
          }
          <section className={styles.checkbox__group}>
            <BlockedIssuersTitle />
            {
              isLoading ?
                <BaseLoading isLoading={true} /> :
                <>
                  {
                    issuersOptions.map( issuer => 
                        <BaseCheckbox
                          key={issuer.name}
                          name={issuer.name}
                          label={issuer.label}
                          isChecked={defaultBlockedIssuersState[issuer.name]}
                          onChange={(value: boolean) => handleBlockedIssuersCheckboxChange(value, issuer.name)}
                        />
                    )
                  } 
                </>
            }
          </section>
          <div className={styles.body__button}>
            <BaseButton title="Confirmar" isLoading={state.isLoading} typeButton="submit" />
          </div>
        </form>
      </div>
    </LayoutV2>
  );
};

export default InstallmentsForm;

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import styles from "./baseInput.module.scss";


type TBaseInput = {
  placeholder?: string
  type?: string
  value?: string | number
  onChange?: any,
  className?: string
  classContainer?: string
  min?: number | string
  max?: number | string
  maxLength?: number
  label?: string | null
  labelError?: boolean
  readOnly?: boolean
  disabled?: boolean
  info?: string
  error?: string | null
  name?: string,
  success?: boolean,
  country?: string

}

const BaseInputPhone: React.FC<TBaseInput> = ({
  placeholder,
  classContainer = "",
  className = "",
  label = null,
  labelError = false,
  info,
  error,
  name,
  country = "ar"
}) => {

  return (
    <div className={`${styles.input} ${classContainer}`}>
      {label &&
        (<span className={`${styles.input__title} ${labelError && styles.label_error}`}> {label} </span>)
      }
      <div className={styles.input__base}>
        <PhoneInput
          inputProps={{
            name
          }}
          enableLongNumbers={true}
          countryCodeEditable={false}
          country={country}
          onlyCountries={["ar"]}
          placeholder={placeholder}
          inputClass={`${styles.phoneInput} ${error && styles.input__error}  ${className} `}
        />


      </div>

      {info && !error && (
        <div className={styles.info}>{info}</div>
      )}

      {error && (
        <div className={styles.input__text_error}>{error}</div>
      )}

    </div>
  );
};

export default BaseInputPhone;

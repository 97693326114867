import { AxiosRequestHeaders } from "axios";
import { useReducer } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../config";
import request from "../../libraries/Request";
import { decrypt, parseDataToPaymentMethod, parseMerchantData } from "../../utils/utilities";
import contextReducer from "./contextReducer";
import { TCredentialForm, TGetMerchantDataResponse, TInstallmentsBody, TIssuerNameProps, TMerchant } from "./contextTypes";
import { initialState } from "./initialState";
import { parseMessageError } from "./utils";

const useContextFunction = () => {

  const navigate = useNavigate();

  const [state, dispatch] = useReducer(contextReducer, initialState);

  /**   
   * @param merchant 
   * @returns 
   * @description create Middleman
   */
  const createMiddleman = async (merchant: TMerchant) => {
    const parsedMerchant = parseMerchantData(merchant);

    const headers: AxiosRequestHeaders = {
      "Content-Type": "application/json",
    };

    dispatch({ type: "BEGIN_OPERATION", payload: true });
    const { data, statusText } = await request.post(`${config.api.createMiddleman}`, parsedMerchant, headers);

    if (statusText) {
      dispatch({ type: "ERROR", payload: statusText });
      return;
    }
    dispatch({ type: "ADD_MERCHANT", payload: data });
    navigate("/configure-credentials");
  };


  /**
 * 
 * @param payload 
 * @description add data to local state
 */
  const updateStateData = async (payload: any) => {

    const decryptedData = JSON.parse(decrypt(payload));

    dispatch({ type: "BEGIN_OPERATION", payload: true });

    dispatch({ type: "PUT_MERCHANT", payload: { ...decryptedData } });
  };

  /**
   * 
   * @param storeId 
   * @description get data merchant and put storeId and Token in LocalState
   */
  const putMerchant = async (storeId: string | number) => {

    const { data, statusText } = await request.get(`${config.api.getMerchant}?user_id=${storeId}`);

    if (statusText) {

      dispatch({ type: "ERROR", payload: parseMessageError(statusText) });

      return;
    }

    dispatch({ type: "PUT_MERCHANT", payload: { ...data } });
  };

  /**
 * 
 * @param storeId 
 * @description get data merchant and put storeId and Token in LocalState
 */
  const setConfigureKey = async (payload: TCredentialForm) => {

    const headers: AxiosRequestHeaders = {
      "Content-Type": "application/json",
    };
    dispatch({ type: "BEGIN_OPERATION", payload: true });

    const completedData = { ...state.merchant, ...payload };
    const parsedPayload = parseDataToPaymentMethod(completedData);

    const { publicKey, privateKey } = payload;
    const { data: dataValidate } = await request.post(`${config.api.validateCredentials}`, { publicKey, privateKey });

    if (!dataValidate.isValid) {
      dispatch({ type: "ERROR", payload: `Alguna de las claves es incorrecta. Asegurate de ingresar "claves de producción"` });
      return;
    }

    const { data, statusText } = await request.post(`${config.api.savePayment}`, parsedPayload, headers);

    if (statusText) {
      dispatch({ type: "ERROR", payload: statusText });
      return;
    }
    dispatch({ type: "PUT_MERCHANT", payload: { ...data } });
    
    sendEmailSuccess(data.jweCredentials);
    navigate("/credentials-success");
  };


  const sendEmailPayway = async () => {
    const email = state.merchant.stores[0].email;
    dispatch({ type: "BEGIN_OPERATION", payload: true });
    const payload = {
      name: state.merchant.name as string,
      email,
      storeId: state.merchant.stores[0].id
    };

    const { statusText } = await request.post(`${config.api.sendEmail}/send?type=payway`, payload);

    if (statusText) {
      dispatch({ type: "ERROR", payload: statusText });
      return;
    }

    dispatch({ type: "SUCCESS_OPERATION", payload: false });
  };


  const sendEmailSuccess = async (jwe) => {
    dispatch({ type: "BEGIN_OPERATION", payload: true });
    const payload = {
      name: state.merchant.name,
      email: state.merchant.email,
      jwe: jwe
    };

    const { statusText } = await request.post(`${config.api.sendEmail}/send?type=created`, payload);

    if (statusText) {
      dispatch({ type: "ERROR", payload: statusText });
      return;
    }

    dispatch({ type: "SUCCESS_OPERATION", payload: false });
  };

  const getMerchantDataFromJwe = async (jwe: string | undefined) => {
    const data: TGetMerchantDataResponse = {
      success: false,
      response: null,
    };

    try {
      const response = await request.get(`${config.api.getInstallmentsAndIssuersByJwe}?jwe=${jwe}`);
      if (response.status == 200) {
        data.success = true;
        data.response = response;
      }
      return data;
    } catch (error) {
      return data;
    }
  };


  const sendInstallmentsData = async (jwe: string | undefined, installmentsBody: TInstallmentsBody, blockedIssuersBody: TIssuerNameProps) => {
    dispatch({ type: "BEGIN_OPERATION", payload: true });

    const payload = {
      jwe: jwe,
      blockedIssuersBody,
      installmentsBody
    };

    try {
      await request.put(`${config.api.updateInstallmentsAndBlockedIssuers}`, payload);
    } catch (error) {
      return error;
    }

    dispatch({ type: "SUCCESS_OPERATION", payload: false });
    navigate("/installments-success");
  };


  return {
    state,
    createMiddleman,
    putMerchant,
    setConfigureKey,
    sendEmailPayway,
    updateStateData,
    sendEmailSuccess,
    sendInstallmentsData,
    getMerchantDataFromJwe
  };
};

export default useContextFunction;


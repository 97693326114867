
const isValidPhone = (value: string) => {
  const text = value.trim();
  if (!text || text === "" || text.length < 17) {
    return false;
  }
  return true;
};


const isValidInput = (value: string) => {
  const text = value.trim();
  if (!text || text === "") {
    return false;
  }
  return true;
};


const isValidName = (value: string) => {
  const invalidNameFormat = /[&Ññ]/;

  const validateInput = isValidInput(value);

  if (!validateInput) {
    return false;
  }
  if (invalidNameFormat.test(value)) {
    return "invalid_character";
  }

  return true;
};

const isValidEmail = (mail: string) => {
  const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const validateInput = isValidInput(mail);

  if (!validateInput) {
    return false;
  }

  if (!mail.match(mailformat)) {
    return false;
  }

  return true;
};

export {
  isValidName,
  isValidEmail,
  isValidInput,
  isValidPhone
};
import { useMerchant } from "context/StateContext/StateProvider";
import useForm from "hooks/useForm";
import { useSearchParams } from "react-router-dom";
import BaseButton from "../../baseComponents/BaseButton";
import BaseImage from "../../baseComponents/BaseImage";
import BaseInput from "../../baseComponents/BaseInput";
import styles from "./configure.module.scss";
import { useEffect } from "react";
import { Metrics } from "metrics/events";
import { logAmplitudeEvent } from "hooks/useAmplitude";
import BaseIcon from "baseComponents/BaseIcons";
import LayoutV2 from "layouts/V2";
import config from "config";

const ConfigureCredentials = () => {
  const { setConfigureKey } = useMerchant();
  const [searchParams] = useSearchParams();
  const queryParams = searchParams.get("q_") as string;

  const { updateStateData, state: { isError, errorMessage, isLoading } } = useMerchant();

  useEffect(() => {
    if (queryParams) {
      updateStateData(queryParams);
    }
  }, []);

  const handleForm = (payload: any) => {
    logAmplitudeEvent(Metrics.PAYWAY_INTEGRATION_TAPPED);
    setConfigureKey(payload);
  };

  const handleLink = () => {
    logAmplitudeEvent(Metrics.PAYWAY_INTEGRATION_HELP_TAPPED);
  };


  const { errors, handleSubmit, success } = useForm(handleForm);

  return (
    <LayoutV2>

      <div className={styles.body}>
        <div className={`${styles.body__title} title`}>
          Ingresá las claves de Payway ventas online
        </div>
        <div className={styles.body__example}>
          <div className={`sub-title`}>{`Buscá en el mail de alta con el asunto "datos de producción" las claves para terminar la configuración. 
            Las claves las verás de la siguiente manera: `}
          </div>
          <div className={styles.body__email}>
            <BaseImage image="img-body-email-payway" />
          </div>
        </div>

        <div className={styles.body__help}>
          <span className="fw-bold">¿No tenés las claves?&nbsp;
            <a href={config.urlFaqModo} target="_blank" className={styles.text_blue} onClick={handleLink} rel="noreferrer" >Te ayudamos a obtenerlas</a>
          </span>
        </div>


        <form className={styles.form} onSubmit={handleSubmit}>
          <BaseInput type="text"
            label={"API Key Pública de Payway"}
            labelError={!!errorMessage || !!errors.idSite}
            info={"Escribí únicamente letras y números"}
            placeholder={"Ej: KI471SF141H4D56A111144444444"}
            name={"publicKey"}
            error={errorMessage ? " " : errors.publicKey}
            success={isError ? false : !!success.publicKey}
          />

          <BaseInput type="text"
            label={"API Key Privada de Payway"}
            labelError={!!errorMessage || !!errors.idSite}
            info={"Escribí únicamente letras y números"}
            placeholder={"Ej: CC4A58FG4E5S41ADEG445531"}
            name={"privateKey"}
            error={errorMessage ? " " : errors.privateKey}
            success={isError ? false : !!success.privateKey}
          />

          <BaseInput
            label={"ID site de Payway"}
            info={"Escribí únicamente números"}
            placeholder={"Ej: 7874126980"}
            name={"idSite"}
            type={"number"}
            error={errorMessage ? " " : errors.idSite}
            labelError={!!errorMessage || !!errors.idSite}
            success={isError ? false : !!success.idSite}
          />
          {errorMessage &&
            <div className={styles.error_box}>
              <BaseIcon icon="icon-warning-circle" width={20} style={styles.warning} />
              {errorMessage}
            </div>
          }

          <div className={`${styles.body__button} ${styles.body__button_first}`}>
            <BaseButton title="Integrar botón de pago MODO" isLoading={isLoading} typeButton="submit" />
          </div>

        </form>
      </div>

    </LayoutV2>
  );
};


export default ConfigureCredentials;
import People from "./people.svg";
import SvgError from "./icon-error.svg";
import SvgWarningBlack from "./icon-warning-black.svg";
import SvgWarning from "./icon-warning.svg";
import SvgWarningCircle from "./icon-warning-circle.svg";
import IconHelpCircle from "./icon-help-circle.svg";
import SvgSuccess from "./icon-success.svg";
import IconStars from "./icon-stars.svg";
import ImgWelcome from "./image-welcome.svg";
import IconModo from "./icon-modo.svg";
import SvgPeopleWarng from "./image-people-warn.svg";

type Map = {
  [key: string]: string | undefined
}

const SvgImage: Map = {
  "default": "",
  "svg-people": People,
  "icon-error": SvgError,
  "icon-warning-black": SvgWarningBlack,
  "icon-warning": SvgWarning,
  "icon-warning-circle": SvgWarningCircle,
  "icon-success": SvgSuccess,
  "svg-stars": IconStars,
  "image-welcome": ImgWelcome,
  "icon-help-circle": IconHelpCircle,
  "icon-modo": IconModo,
  "image-people-warn": SvgPeopleWarng
};

export default SvgImage;


import BaseIcon from "baseComponents/BaseIcons";
import { FC, ReactNode } from "react";

import styles from "./v2.module.scss";


const LayoutV2: FC<{ isHome?: boolean, children: ReactNode }> = ({ isHome = false, children }) => {

  const goTo = (): void => {
    window.open("https://ayuda.modo.com.ar/support/solutions/66000284964", "_blank");
  };

  return (
    <div className={styles.container}>
      {isHome ?
        <div className={styles.stars}>
          <BaseIcon icon="svg-stars" width={200} height={200} />
        </div>
        :
        <>
          <div className={styles.stars}>
            <BaseIcon icon="icon-modo" width={200} height={200} />
          </div>

          <div className={styles.logo}>
            <BaseIcon icon="icon-letter-modo" />
          </div>
        </>
      }



      <div className={`${isHome ? styles.help__home : styles.help}`} onClick={() => goTo()}>
        <BaseIcon icon="icon-help-circle" width={20} />
        <span className="ml-3">Ir a Ayuda</span>
      </div>
      {children}
    </div>
  );
};


export default LayoutV2;
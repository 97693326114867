import { BlockedIssuersProps, DefaultBlockedIssuersState, DefaultInstallmentValue, InstallmentsProps } from "./types";

const installmentsFormInfo = {
  title: "Configuración de cuotas y medios de pago",
  subtitle: "Seleccioná los planes de cuotas y medios de pago que deseas ofrecer a tus clientes a la hora de pagar con MODO",
  installmentsDescription: {
    title: "Planes de cuotas",
    firstDesciption: {
      text: "Elegí las cuotas que tus clientes verán al pagar con MODO. Recordá que en las cuotas sin interés vos absorbés el costo financiero. ",
      link: {
        text: "¿Cuáles son los costos financieros?",
        href: "https://ayuda.payway.com.ar/cobros/cuotas/planes-payway"
      }
    },
    secondDesciption: {
      text: "Para ofrecer los \"Planes Ahora\" primero deberás habilitarlos en Payway. ",
      link: {
        text: "¿Cómo los habilito?",
        href: "https://ayuda.payway.com.ar/cobros/planes-ahora"
      }
    },
  },
  paymentIssuersTitle: "Bloqueo de medios de pago",
  paymentIssuersDescription: "<p>Seleccioná los medios de pago que <b>NO</b> deseas ofrecerle a tus clientes. VISA y MASTERCARD siempre estarán habilitados.</p>"
};

const setDefaultValues = (installmentsResponse: InstallmentsProps, number: number) => {
  
  const {installments, installmentsWithoutInterest, installmentsAhora } = installmentsResponse;

  let currentValue: DefaultInstallmentValue | undefined;

  installments.forEach(element => {
    if (number === element) {
      currentValue = {
        label: `${number} cuotas CON interés`,
         value: `installments`
      };
    } else {
      return undefined;
    }
  });

  installmentsWithoutInterest.forEach(element => {
    if (number === element) {
      currentValue = {
        label: `${number} cuotas SIN interés`,
         value: `installmentsWithoutInterest`
      };
    } else {
      return undefined;
    }
  });

  Object.keys(installmentsAhora).forEach(key => {
    if (number.toString() === key && installmentsAhora[key].coefficient === 1) {
      currentValue = {
        label: `Plan Ahora ${number} SIN interés`,
         value: `installmentsAhoraWithoutInterest`
      };
    } else if (number.toString() === key && installmentsAhora[key].coefficient !== 1) {
      currentValue = {
        label: `Plan Ahora ${number} CON interés`,
         value: `installmentsAhora`
      };
    } else {
      return undefined;
    }
  });

  return currentValue;
};

const setDefaultBlockedIssuersValues = (issuersResponse: BlockedIssuersProps | null, issuersState: DefaultBlockedIssuersState) => {  
  if (issuersResponse?.issuer_name) {
    switch (issuersResponse.issuer_name.type) {
      case "block":
        issuersResponse.issuer_name.value.forEach((item) =>
          Object.defineProperty(issuersState, item, {
            value: true,
          })
        );
        break;
      case "allow":
        issuersResponse.issuer_name.value.forEach((item) => {
          for (const clave in issuersState){
           issuersState[clave] = true;
          }
          Object.defineProperty(issuersState, item, {
            value: false,
          });
        }
      );
        break;
      default:
        return issuersState;
    }
  }

  return issuersState;
};


export {
  setDefaultValues,
  installmentsFormInfo,
  setDefaultBlockedIssuersValues
};